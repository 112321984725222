import { Grid, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { appendImage } from "../../../utils/helper";

const ImagePicker = ({
  register,
  errors,
  image,
  value,
  disabled = false,
  field,
  onChange,
  showError = true
}) => {
  const [logo, setLogo] = useState(image || "");

  useEffect(() => {
    if (image) {
      setLogo(image);
    }
  }, [image]);

  const handleCreateBase64 = useCallback(async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setLogo(base64);
  }, []);

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (!file) {
        alert("Please select an image");
      } else {
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
      }
      fileReader.onerror = (error) => reject(error);
    });
  };

  const deleteImage = (e) => {
    e.preventDefault();
    setLogo(null);
  };

  return (
    <Grid item xs={12}>
      {logo && (
        <div style={{ width: "100px", height: "100px" }}>
          <img
            src={appendImage(logo)}
            width="100%"
            alt="image"
            style={{ maxHeight: "100%" }}
          />
        </div>
      )}
      <label
        style={{
          border: "1px solid #ccc",
          display: "inline-block",
          padding: "6px 12px",
          cursor: "pointer",
        }}
      >
        {field ? (
          <TextField
            disabled={disabled}
            fullWidth
            accept="image/*"
            id="image-upload"
            type="file"
            {...field}
            value={image?.fileName}
            onChange={(event) => {
              onChange(event.target.files);
              handleCreateBase64(event);
            }}
            style={{
              display: "none",
            }}
          />
        ) : (
          <TextField
            disabled={disabled}
            fullWidth
            accept="image/*"
            id="image-upload"
            type="file"
            {...register(value, {
              required: !logo,
              onChange: (e) => {
                onChange(event.target.files);
                handleCreateBase64(e);
              },
            })}
            style={{
              display: "none",
            }}
          />
        )}
        {!logo ? `Add ${value}` : `Change ${value}`}
      </label>
      {showError && errors.image && (
        <span className="error-text">This field is required</span>
      )}
    </Grid>
  );
};

export default ImagePicker;
